@import "bourbon";
@import "vars.sass";

//Load Libs SASS
@import "fonts.sass";
@import "app/libs/swiper/swiper.min";

*::-webkit-input-placeholder {
	color: gray;
	opacity: 1;
	font-family: "SFUIDisplay-Ultralight", sans-serif;
	font-size: 16px;
	font-weight: 300;
	letter-spacing: 0.014em;
	@media (min-width: 500px) {
		font-size: 20px; } }

*:-moz-placeholder {
	color: gray;
	opacity: 1;
	font-size: 16px;
	font-weight: 300;
	font-family: "SFUIDisplay-Ultralight", sans-serif;
	@media (min-width: 500px) {
		font-size: 20px; } }

*::-moz-placeholder {
	color: gray;
	opacity: 1;
	font-size: 16px;
	font-weight: 300;
	font-family: "SFUIDisplay-Ultralight", sans-serif;
	@media (min-width: 500px) {
		font-size: 20px; } }

*:-ms-input-placeholder {
	color: gray;
	opacity: 1;
	font-size: 16px;
	font-weight: 300;
	font-family: "SFUIDisplay-Ultralight", sans-serif;
	@media (min-width: 500px) {
		font-size: 20px; } }

body input:focus:required:invalid,
body textarea:focus:required:invalid {
	color: #666; }
body input:required:valid,
body textarea:required:valid {
	color: #666; }

body {
	font-size: 16px;
	min-width: 320px;
	position: relative;
	line-height: 1.6;
	font-family: "SFUIDisplay-Light", sans-serif;
	overflow-x: hidden; }

.wrap {
	width: 100%;
	position: relative; }

.hidden {
	display: none; }

.btn {
	font-size: 20px;
	outline: none;
	padding: 0;
	background-color: #00b5ee;
	border-radius: 10px;
	border-radius: 10px;
	border: 1px solid transparent;
	color: #fff;
	transition: all .3s ease;
	font-family: "SFUIDisplay-Ultralight", sans-serif;
	font-size: 20px;
	letter-spacing: 0.02em;
	&:hover {
		color: #00b5ee;
		background-color: #fff;
		border: 1px solid #00b5ee;
		transition: all .3s ease; } }

.enter-button {
	width: 115px;
	height: 30px;
	display: none;
	font-size: 14px;
	@media (min-width: 1050px) {
		display: inline-block; } }

.gumburger {
	position: fixed;
	width: 41px;
	height: 31px;
	top: 22px;
	right: 15px;
	z-index: 1000;
	display: block;
	@media (min-width: 1200px) {
		right: 40px;
		display: none; } }
.McButton {
	$bar-w: 41px;
	$bar-h: 3px;
	$bar-color: gray;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -($bar-w /2);
	margin-top: -($bar-w /2);
	width: $bar-w;
	height: ($bar-w *3) /4;
	cursor: pointer;
	b {
		position: absolute;
		right: 0;
		width: $bar-w;
		height: $bar-h;
		background-color: $bar-color;
		border-radius: 2px;
		&:nth-child(1) {
			top: 0;
			width: 35px; }
		&:nth-child(2) {
			top: 50%;
			width: 31px; }
		&:nth-child(3) {
			top: 100%; } } }
.McButton.active {
	b {
		background-color: gray;
		&:nth-child(1) {
			top: 0;
			width: 100%; }
		&:nth-child(2) {
			top: 50%;
			width: 100%; }
		&:nth-child(3) {
			top: 100%;
			width: 100%; } } }

.logo {
	width: 185px;
	height: 62px; }

.header {
	width: 100%;
	background: rgba(#fff, .95);
	height: 70px;
	position: fixed;
	z-index: 500;
	border-bottom: 1px solid rgba(gray, 0.3);
	-webkit-backdrop-filter: saturate(200%) blur(15px);
	&__wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		&--white {
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: #fff;
			z-index: 10;
			opacity: 0.3; } }
	&__list {
		margin: 0 0 0 55px;
		padding: 0;
		list-style-type: none;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex: 1;
		display: none;
		@media (min-width: 1200px) {
			display: flex; } }
	&__item {
		margin-right: 60px; }
	&__link {
		font-family: "SFUIDisplay-Light", sans-serif;
		font-size: 16px;
		color: gray;
		text-decoration: none;
		transition: all .3s ease;
		&:hover {
			color: #60a6f1;
			transition: all .3s ease; } }
	&__phone {
		text-align: left;
		flex: 1;
		display: none;
		@media (min-width: 1200px) {
			display: block;
			text-align: right; }
		a {
			color: #000;
			font-family: "SFUIDisplay-Regular", sans-serif;
			font-size: 24px;
			font-weight: 400;
			line-height: 20px;
			outline: none;
			text-decoration: none; } } }

.mobile-menu {
	display: none;
	opacity: 0; }

.first-screen {
	width: 100%;
	height: 100%;
	background-color: #fff;
	background-image: url("../img/picture/video_blank.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	overflow: hidden;
	position: relative;
	z-index: 50;
	top: 0;
	left: 0;
	z-index: 50;
	@media (min-width: 700px) {
		height: 100vh; }
	&__wrap {
		height: 100%;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		z-index: 20;
		position: relative;
		@media (min-width: 700px) {
			height: 100vh; } }
	&__logo {
		margin-top: 130px;
		width: 120px;
		@media (min-width: 1050px) {
			margin-top: 0;
			width: auto; }
		img {
			width: 178px;
			height: auto; } }
	&__title {
		color: #000;
		text-align: center;
		font-size: 25px;
		font-family: "SFUIDisplay-Light", Arial, sans-serif;
		font-weight: 400;
		line-height: 30px;
		@media (min-width: 600px) {
			font-size: 40px;
			line-height: 1.1;
			letter-spacing: -0.027em;
			margin-bottom: 35px; }
		&--left {
			text-align: left;
			margin-bottom: 20px; } }
	&__text {
		p {
			color: grey;
			margin: 0 auto 25px auto;
			max-width: 700px;
			font-size: 16px;
			font-family: "SFUIDisplay-Light", Arial, sans-serif;
			font-weight: 400;
			line-height: 1.3;
			letter-spacing: .034em;
			@media (min-width: 500px) {
				font-size: 18px;
				line-height: 1.6; } } }
	&__button {
		width: 290px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		border-radius: 10px;
		display: none;
		margin: 0 auto 30px auto;
		@media (min-width: 700px) {
			margin-bottom: 0; }
		@media (min-width: 1050px) {
			display: block; }

		&--blue {
			display: block;
			margin-top: 40px;
			color: #fff;
			background-color: #60a6f1;
			border: 1px solid #60a6f1; } } }

.grid {
	position: relative;
	width: 100%;
	height: 720px;
	overflow: hidden;
	background-color: #6a5044; }

.package {
	background: linear-gradient(to bottom, rgba(237,237,237,1) 0%, rgba(255,255,255,1) 3%, rgba(255,255,255,1) 100%);
	padding: 30px 0 30px 0;
	overflow: hidden;
	position: relative;
	z-index: 20;
	@media (min-width: 700px) {
		padding: 55px 0 100px 0; }
	&__wrap--gray {
		position: absolute;
		width: 100%;
		height: 130%;
		top: 0;
		left: 0;
		background-image: url("../img/picture/package_bg.png");
		background-repeat: no-repeat;
		background-position: center 500px;
		z-index: 10;
		display: none;
		@media (min-width: 1050px) {
			display: block; } }
	&__wrap--red {
		position: absolute;
		width: 100%;
		height: 170%;
		top: 0;
		left: 0;
		z-index: 30;
		display: none;
		background-image: url("../img/picture/blue-items1.png");
		background-repeat: no-repeat;
		background-position: center 500px;
		@media (min-width: 1050px) {
			display: block; } }
	&__slider-control {
		margin: 10px auto 70px auto;
		padding: 0;
		list-style-type: none;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		z-index: 60;
		position: relative;
		flex-direction: column;
		@media (min-width: 700px) {
			flex-direction: row; }
		@media (min-width: 1050px) {
			width: 70%; } }
	&__slider-item {
		color: #ccc;
		font-size: 18px;
		font-weight: 300;
		font-style: italic;
		line-height: 24px;
		transition: all .4s ease;
		position: absolute;
		width: 300px;
		left: 50%;
		transform: translate(-50%);
		margin-bottom: 10px;
		display: none;
		@media (min-width: 500px) {
			width: 450px; } }
	&__slider-item.active {
		display: block; }
	&__text {
		p {
			color: grey;
			margin: 0 auto 60px auto;
			max-width: 795px;
			font-size: 16px;
			font-family: "SFUIDisplay-Light", Arial, sans-serif;
			font-weight: 300;
			line-height: 1.3;
			text-align: center;
			letter-spacing: .012em;
			@media (min-width: 500px) {
				font-size: 20px;
				line-height: 1.4; } } }
	&__slider {
		overflow: hidden;
		position: relative;
		max-width: 1140px;
		height: 165px;
		margin: 0 auto;
		margin-top: 25px;
		z-index: 40;
		@media (min-width: 700px) {
			height: 440px; }
		@media (min-width: 1030px) {
			height: 700px;
			margin-top: 118px; }
		@media (min-width: 1100px) {
			height: 640px; } }
	&__ipad {
		position: absolute;
		width: 260px;
		height: 155px;
		z-index: 20;
		overflow: hidden;
		background-image: url("../img/picture/slider_bg.png");
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		left: 50%;
		transform: translate(-50%);
		@media (min-width: 700px) {
			width: 614px;
			height: 372px; }
		@media (min-width: 980px) {
			width: 1024px;
			height: 445px; }
		@media (min-width: 1050px) {
			width: 100%;
			height: 620px; } } }

.step {
	margin: 30px 0 0 0;
	padding: 0;
	list-style-type: none;
	display: flex;
	justify-content: center;
	z-index: 15;
	position: relative;
	flex-direction: column;
	@media (min-width: 500px) {
		margin: 93px 0 0 0; }
	@media (min-width: 700px) {
		flex-direction: row;
		justify-content: space-around; }
	@media (min-width: 780px) {
		padding: 0 70px; }
	&__item {
		max-width: 480px;
		height: 410px;
		margin-bottom: 20px;
		padding: 44px 30px 0 30px;
		background-color: rgba(#f7f7f7, .5);
		border-radius: 50px;
		text-align: center;
		margin-right: 0;
		@media (min-width: 500px) {
			margin-right: 24px;
			height: 480px; }
		&:last-child {
			margin-right: 0; }
		img {
			display: block;
			margin: 0 auto;
			width: 60%;
			height: auto;
			opacity: 0.5;
			@media (min-width: 980px) {
				width: 195px;
				height: 195px; } } }
	&__title {
		display: block;
		margin: 45px 0 33px 0;
		color: #000;
		font-size: 30px;
		font-weight: 300;
		line-height: 1; }
	&__content {
		color: gray;
		font-size: 16px;
		font-weight: 400;
		@media (min-width: 500px) {
			font-size: 20px; } } }

.gallery-top {
	width: 237px;
	height: 135px;
	z-index: 40;
	@media (min-width: 700px) {
		width: 567px;
		height: 322px; }
	@media (min-width: 980px) {
		width: 682px;
		height: 387px; }
	@media (min-width: 1050px) {
		width: 950px;
		height: 538px;
		z-index: 1; }
	.swiper-slide {
		background-size: cover; }
	img {
		width: 100%;
		height: auto; } }
.swiper-slide {
	text-align: center;
	font-size: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-position: center center;
	background-repeat: no-repeat; }
.swiper-button-next {
	top: 44%;
	height: 80px;
	margin-top: -40px;
	background-image: url("../img/icons/arrow-next.svg");
	background-repeat: no-repeat;
	z-index: 400;
	display: none;
	@media (min-width: 1030px) {
		display: block; } }
.swiper-button-prev {
	top: 44%;
	height: 80px;
	margin-top: -40px;
	background-image: url("../img/icons/arrow-prev.svg");
	background-repeat: no-repeat;
	z-index: 400;
	display: none;
	@media (min-width: 1030px) {
		display: block; } }

.gallery-thumbs {
	width: 210px;
	height: 370px;
	bottom: 58px;
	right: 50%;
	margin-right: -105px;
	overflow: hidden;
	position: absolute;
	z-index: 23;
	@media (min-width: 700px) {
		width: 148px;
		height: 263px;
		bottom: 40px;
		right: 59px;
		margin-right: auto; }
	@media (min-width: 1000px) {
		width: 200px;
		height: 355px;
		bottom: 83px;
		right: 67px; }
	@media (min-width: 1050px) {
		width: 246px;
		height: 438px;
		bottom: 101px;
		right: 96px; }
	.swiper-slide {
		background-size: cover; }
	img {
		width: 100%;
		height: auto; } }

.form-section {
	padding-top: 35px;
	max-width: 750px;
	margin: 0 auto;
	text-align: center;
	z-index: 60;
	position: relative;
	form {
		max-width: 595px;
		margin: 0 auto;
		margin-top: -18px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		input {
			display: inline-block;
			width: 100%;
			height: 50px;
			margin-bottom: 10px;
			border: 1px solid #e0e0e0;
			border-radius: 6px;
			padding-left: 30px;
			box-sizing: border-box;
			@media (min-width: 500px) {
				width: 290px; }
			&:focus {
				outline: none;
				box-shadow: 0px 0px 5px 0px rgba(50,50,50,0.1); } }
		textarea {
			display: block;
			width: 100%;
			height: 150px;
			margin-top: 29px;
			margin-bottom: 35px;
			border: 1px solid #e0e0e0;
			border-radius: 6px;
			padding-left: 30px;
			resize: none;
			&:focus {
				outline: none;
				box-shadow: 0px 0px 5px 0px rgba(50,50,50,0.1); } }
		button {
			display: block;
			margin: 0 auto;
			width: 290px;
			height: 50px;
			margin-top: 15px;
			outline: 0;
			padding: 0;
			background-color: #00b5ee;
			color: #fff;
			font-size: 20px;
			border-radius: 10px;
			border: 1px solid transparent;
			transition: all .3s ease;
			&:hover {
				color: #00b5ee;
				border: 0;
				background-color: #fff;
				border: 1px solid #00b5ee;
				transition: all 0.3s ease; } } } }
.form-error input {
	border-color: #00b5ee !important; }

#success-message {
	display: none;
	font-size: 24px;
	padding-bottom: 50px; }

.yandex-map-block {
	width: 100%; }
.yandex-map {
	width: 100%;
	height: 501px;
	position: relative;
	z-index: 50; }

.prefooter {
	padding: 40px 0;
	background-color: #f2f2f2;
	border-top: 1px solid #fff;
	position: relative;
	z-index: 50;
	&__wrap {
		display: flex;
		justify-content: space-between;
		align-items: center; }
	&__menu {
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: none;
		@media (min-width: 480px) {
			display: block; } }
	&__info {
		margin: 0 auto;
		@media (min-width: 480px) {
			margin: 0; } }
	&__list {
		margin: 0;
		padding: 0;
		list-style-type: none;
		text-align: center;
		@media (min-width: 480px) {
			text-align: right; } }
	&__phone {
		a {
			color: gray;
			font-size: 30px;
			font-weight: 400;
			text-decoration: none;
			outline: none; } }
	&__mail {
		a {
			color: #60a6f1;
			font-size: 14px;
			font-weight: 300;
			line-height: 20px;
			text-decoration: underline;
			outline: none; } }
	&__adress {
		color: #999;
		font-size: 14px;
		font-weight: 300;
		line-height: 20px;
		span {
			display: block; } } }
.footer {
	background-color: #fff;
	position: relative;
	z-index: 50; }
.footer__wrap {
	height: 120px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	flex-direction: column;
	@media (min-width: 600px) {
		flex-direction: row;
		height: 100px;
		justify-content: space-between; } }
.footer__copiright {
	padding-left: 30px;
	background-image: url("../img/icons/icon_copyright.svg");
	background-repeat: no-repeat;
	background-position: left center;
	background-size: 20px;
	@media (min-width: 600px) {
		background-size: 26px;
		padding-left: 38px; }
	span {
		display: block;
		color: #808080;
		font-size: 10px;
		font-weight: 400;
		line-height: 12px;
		@media (min-width: 600px) {
			font-size: 12px;
			line-height: 14px; } } }
.social {
	padding: 0;
	margin: 8px auto;
	@media (min-width: 480px) {
		padding-left: 40px; } }
.social__item {
	width: 20px;
	height: 20px;
	display: block;
	margin: 0 5px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	display: inline-block;
	vertical-align: middle;
	@media (min-width: 400px) {
		width: 24px;
		height: 24px; }
	a {
		display: block;
		height: 24px; } }
.social__item_vk {
	background-image: url("../img/icons/icon-vk.svg"); }
.social__item_fb {
	background-image: url("../img/icons/icon-fb.svg"); }
.social__item_inst {
	background-image: url("../img/icons/instagram_icon.svg"); }
.social__item_skype {
	background-image: url("../img/icons/icon-skype.svg"); }
.social__item_youtube {
	background-image: url("../img/icons/icon_youtube.svg"); }
.footer__studio {
	text-align: right;
	line-height: 14px;
	margin-top: 15px;
	@media(min-width: 500px) {
		margin-top: 0; }
	a {
		color: #808080;
		position: relative;
		font-size: 10px;
		font-weight: 400;
		line-height: 12px;
		text-decoration: none;
		outline: none;
		padding-right: 60px;
		display: block;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 46px;
			height: 25px;
			background-image: url("../img/icons/icon-bakin.svg");
			background-repeat: no-repeat; }
		@media (min-width: 600px) {
			font-size: 12px;
			line-height: 14px; } } }

// Overlay
.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	min-height: 100%;
	top: 0;
	left: 0;
	background-image: linear-gradient(to top, #fff 0%, #fff 100%);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	z-index: 500; }
/* Menu style */
.overlay nav {
	text-align: center;
	position: relative; }
.overlay .overlay__phone {
	text-align: left;
	position: relative;
	margin-top: 15px;
	margin-left: 15px;
	@media (min-width: 1100px) {
		margin-left: 40px;
		margin-top: 17px; } }
.overlay .overlay__footer {
	position: relative; }
.overlay ul {
	list-style: none;
	padding: 0;
	margin: 0 auto;
	display: inline-block;
	height: 100%;
	position: relative; }
.overlay ul li {
	display: block;
	backface-visibility: hidden;
	text-align: center;
	@media (min-width: 1100px) {
		display: inline-block;
		text-align: left; } }
.overlay ul li a {
	display: block;
	padding: 0 40px;
	color: gray;
	font-family: "SFUIDisplay-Light", Arial, sans-serif;
	font-size: 30px;
	font-weight: 400;
	line-height: 40px;
	transition: color 0.2s;
	text-decoration: none;
	outline: none;
	background-image: none;
	background-repeat: no-repeat;
	background-position: right center; }
.overlay ul li a:hover,
.overlay ul li a:focus {
	color: gray; }
/* Effects */
.overlay-hugeinc {
	opacity: 0;
	visibility: hidden;
	transition: opacity .5s, visibility 0s .5s; }
.overlay-hugeinc.open {
	opacity: 1;
	visibility: visible;
	transition: opacity .5s; }
.overlay-hugeinc nav,
.overlay-hugeinc .overlay-close,
.overlay-hugeinc .overlay__phone,
.overlay-hugeinc .overlay__footer,
.overlay-hugeinc .overlay__footer-copyright {
	opacity: 0;
	transition: opacity .4s .4s; }
.overlay-hugeinc.open nav,
.overlay-hugeinc.open .overlay-close,
.overlay-hugeinc.open .overlay__phone,
.overlay-hugeinc.open .overlay__footer,
.overlay-hugeinc.open .overlay__footer-copyright {
	opacity: 1;
	transition-delay: .4s; }
.overlay-hugeinc.close nav,
.overlay-hugeinc.close .overlay-close,
.overlay-hugeinc.close .overlay__phone,
.overlay-hugeinc.close .overlay__footer,
.overlay-hugeinc.close .overlay__footer-copyright {
	transition-delay: 0s; }
.overlay__phone--number {
	display: block;
	color: gray;
	font-family: "SFUIDisplay-Light", Arial, sans-serif;
	font-size: 30px;
	font-weight: 300;
	line-height: 20px;
	text-decoration: none;
	@media (min-width: 400px) {
		font-size: 30px; } }
.overlay__phone--time {
	display: block;
	color: #000;
	font-family: "SFUIDisplay-Light", Arial, sans-serif;
	font-size: 12px;
	font-weight: 300;
	line-height: 17px;
	@media (min-width: 400px) {
		font-size: 14px;
		font-weight: 300;
		line-height: 35px; } }
.overlay__footer-copyright {
	color: #ccc;
	font-size: 11px;
	font-weight: 300;
	line-height: 20px;
	@media (min-width: 400px) {
		font-size: 14px; } }
.overlay__menu .overlay__item {
	&:last-child {
		a {
			padding: 0; } } }
.overlay__menu .overlay__item a {
	color: #e8e8e8;
	font-size: 14px;
	font-weight: 300;
	line-height: 20px;
	text-decoration: underline;
	padding: 0 19px;
	background-image: none;
	outline: none;
	&:hover {
		text-decoration: none; } }
.overlay__footer {
	padding: 0 15px;
	height: 80px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media (min-width: 500px) {
		padding: 0 40px; } }
.overlay .overlay__menu {
	height: auto;
	margin: 0; }

.swiper-pagination {
	position: relative;
	text-align: center;
	transition: .3s;
	transform: translate3d(0,0,0);
	z-index: 50;
	width: 75%;
	@media (min-width: 700px) {
		width: 70%; } }

.swiper-pagination-bullet {
	width: auto;
	height: auto;
	display: inline-block;
	border-radius: 100%;
	background: #000;
	opacity: .2; }

.angry__menu-wrap {
	width: 290px;
	display: none;
	align-items: flex-start;
	justify-content: space-between;
	@media (min-width: 700px) {
		display: flex; } }
.angry__menu {
	&--event {} }

.angry__menu-title {
	display: block;
	margin-bottom: 30px;
	color: #505050;
	font-size: 16px;
	line-height: 20px;
	text-transform: uppercase; }

.angry__menu-link {
	color: #999;
	font-size: 14px; }

.paralax_container {
    width: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    z-index: 1; }
.paralax_wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
    transform: translate3d(0,0,0); }

.paralax_slide {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate3d(0,0,0);
    flex-shrink: 0;
    max-width: 100%;
    height: 100%;
    position: relative; }

